import React from "react";
import ReactDOM from "react-dom";

import SemiCircleURL from "/assets/semi-circle.jpg";
import BlackScholesURL from "/assets/black-scholes-model.svg";
import ShowYourWorkURL from "/assets/show-your-work.jpg";
import KurtosisExplanationURL from "/assets/kurtosis-explanation.jpg";
import VolatilitySmileURL from "/assets/volatility-smile.jpg";
import ExpectedProfitLossURL from "/assets/expected-profit-loss.jpg";
import IVStatisticalArbitrageURL from "/assets/iv-statistical-arbitrage.jpg";

export default class PredictableStableAndLow extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <>
        <div className="header">
          <div className="image-wrapper">
            <img src={SemiCircleURL} />
          </div>
          <h2>Strategy</h2>
          <h1>Predictable, Stable, and Low.</h1>
        </div>
        <article>
          <div className="description">&ldquo;Predictable, Stable, and Low&rdquo; is a strategy that finds statistical arbitrage in the volatility smile by buying low Implied Volatility options and selling high Implied Volatility options on the same equities.</div>
          <h2>Primer on Options</h2>
          <section>
            <p>You're familiar with how options work, but it's important to look at them through the context and optics of the financial markets. Options function as a contract to lock in a price in which you have the <i>option</i> to (but are not forced to) purchase a security until a set expiration in the future. A simple example is a contract that allows for an investor to buy one share of Apple stock for $100/share a year from now.</p>
            <p>In order for that contract to take place, there must be two parties involved: <i>a buyer</i> and <i>a seller</i>. The buyer is motivated to form a contract with a seller if they think the share price of Apple stock will be greater in one year's time. <i>The seller</i> is motivated to sell the contract if they think the share price of Apple will be less than the combined strike price of the option and the premium they charge.</p>
            <p>All is well, we have two interested parties. The buyer asks the seller, <i>&ldquo;How much for a contract to buy a $100 share of Apple stock in 1 year's time?&rdquo;</i></p>
            <p>This is actually an unsuspectingly difficult question to answer. You can expect the contract cost <i>(premium)</i> to be positive because you're extending the unlimited upside of buying Apple stock for $100 in a year&rsquo;s time even if the price is higher, but not the obligation to buy it if it's lower. How much should the premium be, however?</p>
          </section>
          <h2>The Black Scholes Model</h2>
          <section>
            <p>You can intuitively guess a few factors that may contribute to the option's premium. Perhaps the amount the stock moves, how far into the future you have until the option expires, and how volatile the stock's movements are. One less intuitive factor is opportunity cost — what is the cost of spending your money on this premium rather than for example, putting that money in your bank account? Or, more specifically in terms of the model, investing your money in a risk-free asset such as a <i>3-Mo US Treasury Bill</i>.</p>
            <div className="center">
              <div className="figure inline-block">
                <div className="figure--text"><b>Fig 1.</b> The Black Scholes model for calculating option premium.</div>
                <img src={BlackScholesURL} />
              </div>
            </div>
            <p>The variable inputs to the model are:</p>
            <ul>
              <li><b><i>T — t:</i></b> Length of option (years)</li>
              <li><b><i>S:</i></b> Current stock price</li>
              <li><b><i>K:</i></b> Strike price</li>
              <li><b><i>r:</i></b> Risk-free rate <i>(3-Mo US Treasury Bill)</i></li>
              <li><b><i>σ:</i></b> Asset volatility (1-year standard deviation)</li>
            </ul>
            <p>If we apply these numbers to the case of the above contract scenario involving the purchase of a 1-year Apple call option with a strike of $100, along with some example parameters, we can calculate like below.</p>
            <div className="center">
              <div className="figure inline-block">
                <div className="figure--text"><b>Fig 2.</b> A Black Scholes model for a 1-Year Apple Contract with a strike of $100, current stock price of $102, volatility of 12%, and risk-free rate of 5%.</div>
                <img src={ShowYourWorkURL} />
              </div>
            </div>
            <p>The Black Scholes pricing model gives us a value of <i>$8.93</i> for the aforementioned contract. For a long time in the United States markets as well as abroad, the Black Scholes model was taken as a near-perfect means of pricing derivatives on-the-fly.  One thing to note, is that the Black Scholes model calculates premiums using the CNDf (cumulative normal distribution function), something you may be familiar with as a typical &ldquo;bell curve&rdquo;. It makes the assumption that the equity it is modelling has returns that fall under a normal distribution.</p>
            <p>Come Fall of 1987, a depressed market and confluence of intra-market dynamics led to automated selling of shares as the market continued to drop, creating a self-reinforcing dive in the market. By the end of the day, the Dow Jones was down over 22%.</p>
            <p>While the markets recovered, the Black Scholes model's legitimacy didn't. The model assumes a normal distribution, but if the market's distribution was actually normal, the chance of a 22% drop in a single day is all but impossible (the same probability as you having been born 14&rsquo;3&rdquo;).</p>
            <p>Statisticians soon found that almost all equities don't, in fact, have a normal distribution, but more closely resemble a <i>skew-normal distribution</i> with a higher-than-average <i>kurtosis</i>: a high propensity for large, unexpected swings in movement.</p>
            <div className="center">
              <div className="figure inline-block">
                <div className="figure--text"><b>Fig 3.</b> An normal distribution of daily returns (as expected by Black Scholes) vs. a distribution with higher kurtosis (more similar to reality).</div>
                <img src={KurtosisExplanationURL} />
              </div>
            </div>
            <p>In fact, the presence of kurtosis is a statistical double-whammy. It gives the appearance of lower volatility than a similar &ldquo;normal distribution&rdquo; as many observations fall close to the mean, but in fact is prone to much larger swings than the normal probability model accounts for, signalling a high risk of <i>Black Swan</i> events; catastrophic, unpredictable events with large ramifications on equity prices.</p>
            <p>Typically the kind of kurtosis the market sees is associated with a positive skew — meaning the majority of outliers tend to be <i>negative</i>.</p>
          </section>
          <h2>Implied Volatility</h2>
          <section>
            <p>It's commonly said that Implied Volatility is what happens when you take the wrong number (an option's premium) and put it into wrong formula (Black Scholes) to get the right answer.</p>
            <p>Implied volatility is the product of a reverse-engineered Black Scholes model to tell what the volatility of a stock (assuming a normal distribution) would <i>have to be</i> to give the current premium of an option.</p>
            <p>For example, take an Apple call option with a strike at 105 that is expiring tomorrow and is selling for $0.51, while the stock is at $100/share, with volatility of 20%. The Black Scholes model would expect for that option to be worth approximately nothing ($0.0000001) — as the chance of Apple increasing that much in a day given a normal distribution is close to zero. However, participants in the market know that Apple has a high <i>Kurtosis</i> (susceptability to tail risk), and so they're willing to pay more.</p>
            <p>The Implied Volatility in this case is calculated by starting with the end result of how much we are willing to pay, and working back to find what volatility Apple stock would have to have in order for that price to be reasonable. In this case, it's a volatility of 100%.</p>
            <p>In the case of Implied Volatility being 100%, the market would be expecting a 21% chance of the stock going up at least 5% tomorrow (given the aforementioned price of $0.51), which could be due to investor knowledge of earnings releases, or general risk of tail events in the market. A normal distribution prediction of Apple's returns tomorrow, however, figures that a movement of four standard deviations would need to occur to hit $105/share, which would happen in <i>less than 0.00001%</i> of normal outcomes.</p>
            <p>This makes a great case for why the normal distribution should not be used to model option values, while also showing the importance of Implied Volatility as a metric to assess percieved tail risk.</p>
            <p>An important trend to note is that volatility tends to form a "smile", where Implied Volatility is lowest near the current stock price and highest in deep in-the-money and out-of-the-money calls and puts.</p>
            <div className="center">
              <div className="figure inline-block">
                <div className="figure--text"><b>Fig 4.</b> A volatility smile present in various strikes of the same equity and same expiration, tracking the <i>Implied Volatility</i> investors are willing to pay at each price.</div>
                <img src={VolatilitySmileURL} />
              </div>
            </div>
          </section>
          <h2>Calculating Theoretical Implied Volatility</h2>
          <section>
            <p>If it is true that distributions are not normal but in fact have some components of skewness and kurtosis that yield a difference in Implied Volatility from what the Black Scholes model would expect, then there should be a way of calculating it.</p>
            <p>A simple, mathematically-light heuristic for figuring Implied Volatility would be to find the distribution of outcomes of an equity, and multiply it by the profitability curve of a given option.</p>
            <div className="center">
              <div className="figure inline-block">
                <div className="figure--text"><b>Fig 5.</b> An expected profit/loss distribution function given a unique distribution.</div>
                <img src={ExpectedProfitLossURL} />
              </div>
            </div>
            <p>By finding this P/L model for a given equity and strike date, you can then assess what the theoretical value of a put or call is and use that as a basis to back out to an Implied Volatility. For example, if on a call at a strike of 102, you expect to break even if you spend $0.14, but they are currently selling for $0.03, you can purchase calls to attempt statistical arbitrage. If the inverse is true, you can write and sell those calls to other investors at $0.14 to attempt statistical arbitrage.</p>
            <div className="center">
              <div className="figure inline-block">
                <div className="figure--text"><b>Fig 6.</b> Statistical arbitrage opportunities shown in a theoretical IV curve vs. an actual IV curve.</div>
                <img src={IVStatisticalArbitrageURL} />
              </div>
            </div>
          </section>
          <h2>The Strategy</h2>
          <section>
            <p>By finding errors in pricing, reflected by higher or lower market Implied Volatilies than we would predict, we can make decisions on whether to buy or sell calls and puts at certain strikes on various equities. The strategy explicity is to sell calls and puts where the market IV is higher than the theoretical IV, and buy calls and puts where the market IV is lower than the theoretical IV.</p>
          </section>
        </article>
      </>
    );
  }
}
